import { Form } from './components/Form';
import { Screen } from './components/Screen';
import { Outlet, Route, Routes, useLocation } from "react-router-dom";

import generic_banner from './assets/generic-form-banner.png';
import { Card } from './components/Card';
import { Button } from './components/Button';
import { Copyright } from './components/Copyright';

//Test

// import axios from 'axios';

const EntryScreen = () => { 
  const { state } = useLocation();

  let banner;

  if (state?.data?.agentId) {
    const imgUrl = `https://agent-banners.s3.us-east-2.amazonaws.com/${state?.data?.agentId}.png`;

    const request = new XMLHttpRequest();
    request.open("GET", imgUrl, true);
    request.send();
    request.onload = function() {
      if (request.status === 200) {
        document.getElementById("banner").src = imgUrl;
      } else {
        document.getElementById("banner").src = generic_banner;
      }
    }
  } else {
    banner = generic_banner;
  }

  return <Screen>
    <div className='absolute top-0 left-0 w-full z-0 overflow-hidden h-3/5'>
      <img id='banner' alt='banner' src={banner} className='absolute top-0 left-0 w-full h-full object-cover' />
      <div class="absolute top-0 left-0 w-full h-full bg-gradient-to-b from-transparent to-neutral-100"></div>
    </div>
    <div className='flex flex-col'>
      <Outlet />
      <Copyright />
    </div>
  </Screen>
};

const Success = () => {
  const { state: { data } } = useLocation();

  const { firstName, lastName, phoneNumber } = data || {};

  return <div className='flex flex-col space-y-5 my-10'>
    {phoneNumber ? <Card
      title={'Thanks, Please check your email!'}
    >
      <div className='text-sm text-center'>
        We have assigned you a State Licensed Representative!
      </div>
      <div className='text-sm text-center'>
        {firstName} {lastName} will follow up with you in the next 24-48 hours to verify your information and explain the programs you may be eligible for.
      </div>
      <div className='text-sm text-center'>
        Don't forget your favorite Hobby, we will use this security word to verify that it's you.
      </div>
      <div className='text-sm text-center'>
        If you'd like to speak to {firstName} now, click the 'Call Now' button below.
      </div>
      <div className='border border-1 w-full' />
      <div className='flex space-x-1 justify-center items-center text-xs text-neutral-500 text-center'>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" className="w-4 stroke-neutral-500">
            <path d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z" fill="none" stroke-miterlimit="10" stroke-width="32" />
            <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M220 220h32v116" />
            <path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M208 340h88" />
            <path className="fill-neutral-500" d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z" />
          </svg>
        </div>
        <div>
          You successfully submitted your responses.
        </div>
      </div>
    </Card> : <Card title={'Thanks, Please check your email!'}>
      <div className='text-sm text-center'>
        We have assigned you a State Licensed Representative who will follow up with you in the next 24-48 hours to verify your information and explain the programs you may be eligible for.
      </div>
      <div className='text-sm text-center'>
        Don't forget your favorite Hobby, we will use this security word to verify that it's you.
      </div>
      <div className='text-sm text-center'>
        Thank You
      </div>
      <div className='flex space-x-1 justify-center items-center text-xs text-neutral-500 text-center'>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" className="w-4 stroke-neutral-500">
            <path d="M248 64C146.39 64 64 146.39 64 248s82.39 184 184 184 184-82.39 184-184S349.61 64 248 64z" fill="none" stroke-miterlimit="10" stroke-width="32" />
            <path fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M220 220h32v116" />
            <path fill="none" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M208 340h88" />
            <path className="fill-neutral-500" d="M248 130a26 26 0 1026 26 26 26 0 00-26-26z" />
          </svg>
        </div>
        <div>
          You successfully submitted your responses.
        </div>
      </div>
    </Card>}
    {phoneNumber && <Button label={<div className='flex space-x-3 justify-center items-center' onClick={() => {
      window.location.href = `tel:+1${phoneNumber}`;
    }}>
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512" className='w-5 fill-white'><path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z" /></svg>
      </div>
      <div>
        Call Now: +1 ({phoneNumber.slice(0, 3)}) {phoneNumber.slice(3, 6)}-{phoneNumber.slice(6, 10)}
      </div>
    </div>} color={'primary'} className='mt-4' />}
  </div>;
}

// const AdminAgent = () => {
//   return <div className='flex flex-col space-y-5'>
//     <Card title={'Upload a file!'}>
//       <div>
//         <form onSubmit={onSubmit}>
//           <input id="file" type="file" name="file" />
//           <input type="submit" />
//         </form>
//       </div>
//     </Card>
//   </div>;
// }

// const onSubmit = async (event) => {
//   event.preventDefault();

//   const formData = new FormData(event.target);

//   const file = formData.get("file");

//   if (!file) {
//     return null;
//   }

//   const {data} = await axios({
//     url: 'http://localhost:8484/uploadBanner',
//     method: 'POST',
//     data: {
//       fileName: file.name,
//     }
//   });

//   const {uploadUrl, key} = data;

//   await axios.put(uploadUrl, file);

//   return key;
// };

const App = () => {
  return <Routes>
    <Route path='/' element={<EntryScreen />}>
      <Route index element={<Form />} />
      <Route path='/agent' element={<Success />} />
      {/* <Route path='/addAgent' element={<AdminAgent />} /> */}
    </Route>
  </Routes>;
}

export default App;
