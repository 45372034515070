import joi from 'joi';
import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { Button } from '../Button';
import { useEffect, useState, useRef } from 'react';
import { Card } from '../Card';
import useFormattedNumber from '../../hooks/useFormattedNumber';
import { useNavigate } from 'react-router-dom';
import { client } from '../../api';
import { Info } from '../Info';

const schema = joi.object({
  email: joi.string().email({ tlds: { allow: false } }).required().messages({
    'string.email': 'Email must be a valid email',
    'any.required': 'Email is required',
  }),
  firstName: joi.string().messages({ 'any.required': 'First name is required' }),
  lastName: joi.string().messages({ 'any.required': 'Last name is required' }),
  policyNumber: joi.string().pattern(/^[A-Za-z0-9-]+$/),

  phoneNumber: joi.string().pattern(/^\(\d{3}\) \d{3}-\d{4}$/).messages({
    'string.pattern.base': 'Phone number must be a valid US phone number without country code',
    'any.required': 'Phone number is required',
  }),
  hobby: joi.string().messages({ 'any.required': 'Hobby is required' }),
  dateOfBirth: joi.date().messages({ 'any.required': 'Date of Birth is required (MM/DD/YYYY)' }),
  streetAddress: joi.string().messages({ 'any.required': 'Street Address is required' }),
  city: joi.string().messages({ 'any.required': 'City is required' }),
  state: joi.string().invalid('state').messages({ 'any.required': 'state is required' }),
  plan: joi.number().required().messages({ 'any.required': 'Preferred Protection Plan is required' }),
});

const SelectOptions = ({ options, setValue, register, formState: { errors } }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    register('plan', { required: true });
  }, [register])

  useEffect(() => {
    if (errors.plan)
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [selectedOption, errors]);

  return (
    <Card title={'Select Preferred Protection Plan:'}>
      <div ref={ref} className='w-64 space-y-4'>
        {options?.map(({ label, value }) => <div>
          <Button
            label={label}
            onClick={() => {
              setSelectedOption(value)
              setValue('plan', value);
            }}
            selected={selectedOption === value}
          />
        </div>)}
        {errors.plan && !selectedOption && <p className='mt-1 text-xs text-red-400'>{errors.plan.message}</p>}
      </div>
    </Card>
  );
};

const SelectState = ({ getValues, register, formState: { errors } }) => {
  return <Card title={'What state do you live in?'}>
    <div className='w-full'>
      <select defaultValue={getValues('state') || ''} {...register('state', { required: true })} className='p-4 justify-center align-center item-center text-center font-bold rounded-full bg-neutral-200 w-full appearance-none' placeholder='State'>
        <option value="state">State</option>
        <option value="AK">Alaska</option>
        <option value="AL">Alabama</option>
        <option value="AR">Arkansas</option>
        <option value="AZ">Arizona</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DC">District of Columbia</option>
        <option value="DE">Delaware</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="IA">Iowa</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="MA">Massachusetts</option>
        <option value="MD">Maryland</option>
        <option value="ME">Maine</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MO">Missouri</option>
        <option value="MS">Mississippi</option>
        <option value="MT">Montana</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NE">Nebraska</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NV">Nevada</option>
        <option value="NY">New York</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PR">Puerto Rico</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VA">Virginia</option>
        <option value="VT">Vermont</option>
        <option value="WA">Washington</option>
        <option value="WI">Wisconsin</option>
        <option value="WV">West Virginia</option>
        <option value="WY">Wyoming</option>
      </select>
      {/* <svg className='relative bottom-10 left-60 stroke-neutral-400' width={25} xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512"><path className='stroke-neutral-400' d="M256 64C150 64 64 150 64 256s86 192 192 192 192-86 192-192S362 64 256 64z" fill="none" stroke-miterlimit="10" stroke-width="32" /><path className='stroke-neutral-400' fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 216l-96 96-96-96" /></svg> */}
      {errors.state && <p className='mt-1 text-xs text-red-400'>{errors.state.message}</p>}
    </div>
  </Card>
};

export const Form = () => {
  const [loading, setLoading] = useState(false);
  const [formattedPhoneNumber, setFormattedNumber] = useFormattedNumber();
  const [formattedDate, setFormattedDate] = useFormattedNumber('NN/NN/NNNN');
  const navigate = useNavigate();
  const form = useForm({
    resolver: joiResolver(schema),
  });

  const { handleSubmit, getValues, register, formState: { errors, touchedFields } } = form;

  const onSubmit = handleSubmit(async (data) => {
    setLoading(true);
    const res = await client({ 
      url: '/',
      method: 'POST',
      data: {
        ...data,
        phoneNumber: data.phoneNumber.replace(/\D/g, ''),
      }
    });

    // Track - Lead
    window.fbq('track', 'Lead');

    setLoading(false);

    navigate('/agent', { state: { data: res.data } });
  });

  return <div className='flex flex-col space-y-10 my-10 mt-32 z-10'>
    <div className='flex flex-col space-y-3'>
      <Card title={`Let's Get You Qualified!`}>
        <div className='text-sm text-center'>
          Answer a few short questions to help us find out which State Regulated Burial Programs you may be eligible for.
        </div>
        <div className='text-sm text-center'>
          We take your security seriously. This form uses SSL encryption to ensure your information is secure.
        </div>
      </Card>
      <SelectOptions
        {...form}
        options={[
          { label: '$5,000', value: 5000 },
          { label: '$10,000', value: 10000 },
          { label: '$20,000', value: 20000 },
          { label: '$25,000', value: 25000 },
        ]}
      />
      <SelectState {...form} />
      <Card title={`Choose a reminder word so you won't forget. What is your favorite hobby?`}>
        <input placeholder='Enter your answer.' name='hobby' id='hobby' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.hobby ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('hobby') || ''} {...register('hobby', { required: true })} />
        {touchedFields.hobby && errors.hobby && <p className='mt-1 text-xs text-red-400'>{errors.hobby.message}</p>}
      </Card>
      <Card
        title={'Contact Information'}
        subtitle={'We will use this information to determine which programs are available.'}
        prompt={false}
        tooltip={'Your personal information.'}
      >
        <label className='w-full'>
          <span class="block text-xs font-medium text-slate-gray pb-1">
            Email
          </span>
          <input name='email' id='email' type="email" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.email ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('email') || ''} {...register('email', { required: true })} />
          {touchedFields.email && errors.email && <p className='mt-1 text-xs text-red-400'>{errors.email.message}</p>}
        </label>
        <label className='w-full'>
          <span class="block text-xs font-medium text-slate-gray pb-1">
            First Name
          </span>
          <input name='firstName' id='firstName' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.firstName ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('firstName') || ''} {...register('firstName', { required: true })} />
          {touchedFields.firstName && errors.firstName && <p className='mt-1 text-xs text-red-400'>{errors.firstName.message}</p>}
        </label>
        <label className='w-full'>
          <span class="block text-xs font-medium text-slate-gray pb-1">Last Name
          </span>
          <input name='lastName' id='lastName' type="text" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.lastName ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('lastName') || ''} {...register('lastName', { required: true })} />
          {touchedFields.lastName && errors.lastName && <p className='mt-1 text-xs text-red-400'>{errors.lastName.message}</p>}
        </label>
        <label className='w-full'>
          <span class="block text-xs font-medium text-slate-gray pb-1">
            Phone
          </span>
          <input name='phoneNumber' id='phoneNumber' type="phoneNumber" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.phoneNumber ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('phoneNumber') || ''} {...register('phoneNumber', { required: true })}
            value={formattedPhoneNumber}
            onChange={e => {
              setFormattedNumber(e.target.value);
            }}
          />
          {touchedFields.phoneNumber && errors.phoneNumber && <p className='mt-1 text-xs text-red-400'>{errors.phoneNumber.message}</p>}
        </label>
        <label className='w-full'>
          <div class=" flex space-x-1 items-center text-xs font-medium text-slate-gray pb-1">
            <div>Date of Birth</div>
            <Info>
              In the format MM/DD/YYYY.
            </Info>
          </div>
          <input name='dateOfBirth' id='dateOfBirth' type="dateOfBirth" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.dateOfBirth ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('dateOfBirth') || ''} {...register('dateOfBirth', { required: true })}
            value={formattedDate}
            placeholder='MM/DD/YYYY'
            onChange={e => {
              setFormattedDate(e.target.value);
            }}
          />
          {touchedFields.dateOfBirth && errors.dateOfBirth && <p className='mt-1 text-xs text-red-400'>{errors.dateOfBirth.message}</p>}
        </label>
        <label className='w-full'>
          <span class="block text-xs font-medium text-slate-gray pb-1">
            Street Address
          </span>
          <input name='streetAddress' id='streetAddress' type="streetAddress" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.streetAddress ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('streetAddress') || ''} {...register('streetAddress', { required: true })}
          />
          {touchedFields.streetAddress && errors.streetAddress && <p className='mt-1 text-xs text-red-400'>{errors.streetAddress.message}</p>}
        </label>
        <label className='w-full'>
          <span class="block text-xs font-medium text-slate-gray pb-1">
            City
          </span>
          <input name='city' id='city' type="city" className={`w-full peer p-2 border rounded-xl focus:ring-offset-2 focus:outline-none ${errors.city ? 'focus:ring-2 focus:ring-red-500' : 'focus:ring-2 focus:ring-blue-500'}`} defaultValue={getValues('city') || ''} {...register('city', { required: true })}
          />
          {touchedFields.city && errors.city && <p className='mt-1 text-xs text-red-400'>{errors.city.message}</p>}
        </label>
        <div className='text-xs text-neutral-400 text-center w-full'>
          By clicking "Next", you agree to send your information to Senior Choice Financial who agrees to use it according to their privacy policy.
        </div>
      </Card>
    </div>
    <div className='sticky bottom-5'>
      <Button loading={loading} label={'Next'} onClick={onSubmit} color={'primary'} />
    </div>
  </div>;
};